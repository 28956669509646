import Dexie from "dexie";
import { v4 as uuidv4 } from "uuid";
import { API_URL } from "../App";
import authHeader from "./auth-header";
import authService from "./auth.service";
import siteService from "./site.service";

const db = new Dexie("TagsDatabase");
db.version(1).stores({
  tags: "_id, tagNumber, sequenceNumber",
  servicerecords: "_id, tagId",
});

class TagService {
  async clearDBs() {
    try {
      console.log("clearing tags database");
      await db.tags.clear();
      await db.servicerecords.clear();
      return true;
    } catch (error) {
      console.log("error in tag.service.clearDBs");
      console.log(error);
    }
  }

  async uploadServiceRecords() {
    try {
      const records = await db.servicerecords.toArray();
      if (records && records.length > 0) {
        await Promise.all(
          records.map(async (record) => {
            await this.addOnServer(record);
          })
        );
      }
    } catch (error) {
      console.log("error in uploadServiceRecords");
      throw error;
    }
  }

  async fetchTagsByTicket(ticketId) {
    fetch(API_URL + "tags/ticket/" + ticketId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"],
      },
    })
      .then((res) => {
        const data = res.json();
        return data;
      })
      .then((data) => {
        db.tags.clear();
        return data;
      })
      .then((tags) => {
        db.tags.bulkAdd(tags);
      })
      .catch((error) => {
        console.log("error in fetchTags");
        throw error;
      });
  }

  async fetchTagsBySite(siteId) {
    return fetch(API_URL + "tags/site/" + siteId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"],
      },
    })
      .then((res) => {
        const data = res.json();
        return data;
      })
      .then((tags) => {
        return tags ? tags : [];
      })
      .catch((error) => {
        console.log("error in fetchTags");
        throw error;
      });
  }

  async fetchServiceRecords(ticketId) {
    fetch(API_URL + "servicerecord/workrecord/" + ticketId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"],
      },
    })
      .then((res) => {
        const data = res.json();
        return data;
      })
      .then((data) => {
        db.servicerecords.clear();
        return data.serviceRecords;
      })
      .then((records) => {
        db.servicerecords.bulkAdd(records);
      })
      .catch((error) => {
        console.log("error in fetchServiceRecords");
        throw error;
      });
  }

  async getDbTags(tagList) {
    return await db.tags.filter((tag) => tagList.includes(tag._id)).toArray();
  }

  async getDbRecords() {
    return await db.servicerecords.toArray();
  }

  async getTag(tagId) {
    return db.tags.get(tagId).then((tag) => {
      return tag;
    });
  }

  async newServiceRecord(tag, workRecord) {
    try {

      const user = authService.getCurrentUser();
      const site = siteService.getActiveSite();

      if (!user || !site) throw new Error("user or site error")
      
      const record = {
        _id: uuidv4(),
        userName: user.username,
        siteName: site.name,
        ticketName: workRecord.ticketName,
        machineName: tag.machine.name,
        partServiced: tag.servicePart,
        workDone: tag.serviceTask,
        siteId: site._id,
        userId: user.id,
        tagId: tag._id,
        workrecordId: workRecord._id,
        timestamp: Date.now(),
      };

      await db.servicerecords.add(record);
      return record._id;
    } catch (error) {
      console.log("error in tag.service newServiceRecord");
      throw error;
    }
  }

  async deleteServiceRecord(tagId) {
    try {
      await db.servicerecords.where("tagId").equals(tagId).delete();
    } catch (error) {
      console.log("error in deleteServiceRecord");
      throw error;
    }
  }

  async addOnServer(record) {
    return fetch(API_URL + "servicerecord", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"],
      },
      body: JSON.stringify(record),
    }).then((res) => {
      if (!res.ok) {
        console.log("tag addOnServer error: " + res);
        throw Error("Could not upload Service Record: " + res.status);
      }
      return res.json();
    });
  }

  async deleteOnServer(recordId) {
    console.log(API_URL + "servicerecord/id/" + recordId);
    fetch(API_URL + "servicerecord/id/" + recordId, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"],
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log("tag.service deleteOnServer error");
        throw err;
      });
  }
}

export default new TagService();
