import ProgressBar from "react-bootstrap/ProgressBar";

const Progress = ({ expectedPercent, actualPercent }) => {
  const baseBar = expectedPercent > actualPercent ? actualPercent : expectedPercent;
  const addedBar = Math.abs(expectedPercent - actualPercent)
  const addedVariant = expectedPercent > actualPercent ? "warning" : 'success';
  return (  
    <div>
      <ProgressBar>
        <ProgressBar now={baseBar} key={1} />
        <ProgressBar variant={addedVariant} now={addedBar} key={2} />
      </ProgressBar>
    </div>
  );
};

export default Progress;
