import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import CustomMenu from "../utils/custommenu";
import Form from "react-bootstrap/Form";
import Resizer from "react-image-file-resizer";
import { v4 as uuidv4 } from "uuid";

import authService from "../../services/auth.service";
import siteService from "../../services/site.service";
import tagService from "../../services/tag.service";
import issueService from "../../services/issue.service";

const NewSiteIssue = () => {
  const history = useHistory();
  const user = authService.getCurrentUser();
  const site = siteService.getActiveSite();
  
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [machineName, setMachineName] = useState("");
  const [issueName, setIssueName] = useState("");
  const [issueDetails, setIssueDetails] = useState("");
  const [issuePriority, setIssuePriority] = useState("important");
  const [tagNumber, setTagNumber] = useState("0");
  const [tagId, setTagId] = useState("");
  const [images, setImages] = useState([]);

  // Ensure user logged in
  useEffect(() => {
    if (!user) history.push("/login");
  });

  // Handle user login expired
  useEffect(() => {
    if (error && error.includes("401")) {
      //login expired
      authService.logout();
      history.push("/login");
    }
  }, [error, history]);

  // Ensure selected site
  useEffect(() => {
    if (!site) history.push("/sites");
  });

  // Get the data
  useEffect(() => {
    setLoading(true);
    console.log(site._id)
    tagService
      .fetchTagsBySite(site._id)
      .then((tags) => {
        setTags(tags);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error in fetching data in newsiteissue");
        console.log(error);
        setError("Error fetching data");
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const cancel = () => {
    history.go(-1);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const saveImages = async (e) => {
    try {
      const file = e.target.files[0];
      let tmppath = window.URL.createObjectURL(file);

      console.log("temppath: ", tmppath);
      console.log("filename: ", file.name);

      const image = await resizeFile(file);
      setImages([image, ...images]);
      console.log(images);
    } catch (err) {
      console.log(err);
    }
  };

  const setTag = (e) => {
    let tag = tags.find((t) => t._id === e);
    setTagNumber(tag.tagNumber)
    setTagId(tag._id)
    setMachineName(tag.machine.name)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    let issueData = {
      _id: uuidv4(),
      userName: user.username,
      siteName: site.name,
      machineName: machineName,
      issueName: issueName,
      issueDetails: issueDetails,
      issuePriority: issuePriority,
      resolved: false,
      timestamp: Date.now(),
      tagNumber: tagNumber,
      tagId: tagId,
      siteId: site._id,
      images: images
    };

    try {
      issueService.newSiteIssue(issueData).then(() => {
        history.go(-1);
      });
    } catch (error) {
      console.log(error);
      setError("Unable to save issue");
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {loading && <div className="alert alert-info">loading...</div>}
        {error && <div className="alert alert-danger">{error}</div>}
        <h4 className="card-title text-center font-weight-bold">
          New Site Issue
        </h4>
        <p className="card-text text-center font-weight-bold">
          Notify at Tag #{tagNumber}
        </p>

        <form id="issueform" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder="Issue name"
              type="text"
              required
              value={issueName}
              onChange={(e) => setIssueName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              placeholder="Additional details (optional)"
              rows={3}
              value={issueDetails}
              onChange={(e) => setIssueDetails(e.target.value)}
            />
          </Form.Group>

          <select
            className="custom-select"
            value={issuePriority}
            onChange={(e) => setIssuePriority(e.target.value)}
          >
            <option value="critical">critical</option>
            <option value="important">important</option>
            <option value="routine">routine</option>
          </select>

          <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Label>Add Images</Form.Label>
            <Form.Control
              type="file"
              accept="image/*;capture=camera"
              onChange={(e) => saveImages(e)}
            />
          </Form.Group>

          {images.length > 0 && (
            <div>
              {images.map((i) => (
                <img src={i} alt="" />
              ))}
            </div>
          )}
        </form>

        <Dropdown>
          <Dropdown.Toggle
            className="btn btn-block btn-primary mt-3"
            id="tags-dropdown"
          >
            Notify Near Tag
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenu}>
            {tags.map((e) => {
              return (
                <Dropdown.Item
                  key={e._id}
                  eventKey={e._id}
                  onSelect={(eventKey) => {
                    setTag(eventKey);
                  }}
                >
                  {`${e.machine.name} ${e.servicePart}, Tag ${e.tagNumber}`}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        <div className="btn-group d-flex" role="group" aria-label="...">
          <button onClick={cancel} className="btn btn-block btn-secondary mt-3">
            Cancel
          </button>
          <button
            type="submit"
            form="issueform"
            className="btn btn-block btn-primary mt-3"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewSiteIssue;
