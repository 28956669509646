import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import CustomMenu from "../utils/custommenu";
import authService from "../../services/auth.service";
import siteService from "../../services/site.service";
import tagService from "../../services/tag.service";
import issueService from "../../services/issue.service";

const SiteIssuesByTag = () => {
  const { tagId } = useParams();
  const history = useHistory();
  const user = authService.getCurrentUser();
  const site = siteService.getActiveSite();
  const [tag, setTag] = useState(null);
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Ensure user logged in
  useEffect(() => {
    if (!user) history.push("/login");
  });

  // Ensure selected site
  useEffect(() => {
    if (!site) history.push("/sites");
  });

  // Get the site and any service issues
  useEffect(() => {
    setLoading(true);

    tagService
      .getTag(tagId)
      .then((tag) => {
        setTag(tag);
      })
      .then(() => {
        return issueService.getSiteIssuesByTag(tagId);
      })
      .then((issues) => {
        setIssues(issues);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError("Unable to get issues");
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const cancel = () => {
    history.go(-1);
  };

  const setIssue = (id) => {
    history.push("/siteissuedetails/" + id);
  };


  const SiteIssues = () => {
    let serviceIssues = issues;
    return (
      <>
        <Dropdown>
          <Dropdown.Toggle
            className="btn btn-block btn-primary mt-3"
            id="issues-dropdown"
          >
            Existing Issues
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenu}>
            {serviceIssues.map((e) => {
              return (
                <Dropdown.Item
                  key={e._id}
                  eventKey={e._id}
                  onSelect={(eventKey) => {
                    setIssue(eventKey);
                  }}
                  variant={e.resolved ? "danger" : "secondary"}
                >
                  {`${e.issueName} at ${e.machineName} ${e.tagNumber}`}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {loading && <div>Loading...</div>}
        {error && <div class="alert alert-danger">{error}</div>}
        {!loading && !error && (
          <div>
            <h3 className="card-title text-center">Site Issues</h3>
            <h4 className="card-title text-center">{site.name}</h4>
            {tag && (
              <h4 className="card-title text-center">Tag #{tag.tagNumber}</h4>
            )}
            <SiteIssues />
            <button
              className="btn btn-block btn-outline-danger mt-3"
              onClick={() => {
                history.push({ pathname: "/newsiteissue" });
              }}
            >
              New Site Issue
            </button>
            <button
              onClick={cancel}
              className="btn btn-block btn-secondary mt-3"
            >
              Back
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SiteIssuesByTag;
