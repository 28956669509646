import axios from "axios";
import { API_URL } from "../App"

class AuthService {
  async login(email, password) {
    const response = await axios
          .post(API_URL + "auth/signin", {
              email,
              password,
              app: true
          });
      if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
  }

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();