import authHeader from "./auth-header";
import { API_URL } from "../App";
import { v4 as uuidv4 } from "uuid";

class TimeService {
  async clockIn(userName, siteName, siteId, ticketName,  userId, ticketId, timeType) {

    let timeRecord = {
      _id: uuidv4(),
      userName: userName,
      siteName: siteName,
      siteId: siteId,
      ticketName: ticketName,
      userId: userId,
      workrecordId: ticketId,
      timeType: timeType,
    };

    return fetch(API_URL + "timerecord", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"],
      },
      body: JSON.stringify(timeRecord),
    })
    .then((res) => {
      if (!res.ok) {
        console.log("time record error: " + res);
        throw Error("Could not save time record: " + res.status);
      }
      return res.json();
    })
    .then((data) => {
      localStorage.setItem("time", JSON.stringify(data))
      return data;
    })
    .catch((err) => {
      throw Error(err)
    });
  }

  async clockOut(userId) {
    return fetch(API_URL + "timerecords/clockout", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"],
      },
      body: JSON.stringify({
        userId: userId,
        stopTime: Date.now(),
      }),
    })
    .then((response) => {
      localStorage.removeItem("time");
      return response
    })
    .catch((err) => {
      throw Error(err)
    });
  }

  async getActiveTimeRecord(userId) {
    return fetch(API_URL + "timerecords/active/" + userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"],
      },
    })
    .then((res) => {
      if (!res.ok) {
        if (res.status === 404)
          return null
        else
          throw Error(res.status);
      }
      return res.json();
    })
    .then(res => {
      return(res)
    })
    .catch((err) => {
      throw Error(err)
    });
  }

  getLocalTimeRecord() {
    return JSON.parse(localStorage.getItem('time'));
  }

}
export default new TimeService();
