import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Dropdown } from "react-bootstrap";
import CustomMenu from "../utils/custommenu";
import authService from "../../services/auth.service";
import siteService from "../../services/site.service";
import issueService from "../../services/issue.service";
import tagService from "../../services/tag.service";
import useFetch from "../../services/useFetch";

const Sites = () => {
  const history = useHistory();
  const user = authService.getCurrentUser();
  const site = siteService.getActiveSite();
  const [records, setRecords] = useState(false);
  const [selectedSite, setSelectedSite] = useState("");
  const { data: sites, error, loading } = useFetch("sites");

  // Ensure user logged in
  useEffect(() => {
    if (!user) history.push("/login");
  });

  // Handle user login expired
  useEffect(() => {
    if (error && error.includes("401")) {
      //login expired
      authService.logout();
      history.push("/login");
    }
  }, [error, history]);

  // Load selected site if one is active
  useEffect(() => {
    if (site) setSelectedSite(site);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Check if any records have been created, but not uploaded
  useEffect(() => {
    const getRecords = async () => {
      try {
        const serviceRecords = await tagService.getDbRecords();
        const siteIssues = await issueService.getAllSiteIssues();
        const serviceIssues = await issueService.getAllServiceIssues();

        if (
          serviceRecords.length > 0 ||
          serviceIssues.length > 0 ||
          siteIssues.length > 0
        ) {
          setRecords(true);
        } else {
          setRecords(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getRecords();
  }, []);

  const selectSite = (e) => {
    let site = sites.find((x) => x._id === e);
    setSelectedSite(site);
  };

  // Set active site and load site issues
  const acceptSelectedSite = () => {
    siteService.setActiveSite(selectedSite);
    issueService
      .fetchSiteIssues(selectedSite._id)
      .then(() => {
        history.push("/tickets");
      })
      .catch(console.error);
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {loading && <div className="alert alert-info">Loading...</div>}
        {error && !selectedSite && (
          <div className="alert alert-danger">{error}</div>
        )}
        {records && (
          <div className="alert alert-danger">
            Upload records before changing sites
          </div>
        )}
        {selectedSite && (
          <div>
            <h4 className="card-title text-center font-weight-bold">
              {selectedSite.name}
            </h4>
            <p className="card-text text-center font-weight-bold">
              {selectedSite.address}
            </p>
            <hr />
            <div className="card-body row">
              <ul className="list-group list-group-flush ">
                <li className="list-group-item font-weight-bold">
                  {selectedSite.contactPerson}
                </li>
                <li className="list-group-item">
                  <i className="fa fa-envelope float-right"></i>Email :{" "}
                  <a href={selectedSite.contactEmail}>
                    {selectedSite.contactEmail}
                  </a>
                </li>
                <li className="list-group-item">
                  <i className="fa fa-phone float-right"></i>Phone:{" "}
                  {selectedSite.contactNumber}
                </li>
              </ul>
            </div>
          </div>
        )}
        {!selectedSite && (
          <div>
            <h2 className="mx-auto text-center">Select a Site</h2>
          </div>
        )}
        {sites && (
          <Dropdown>
            <Dropdown.Toggle
              className="btn btn-block btn-primary mt-3"
              id="sites-dropdown"
            >
              Select Site
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu}>
              {sites.map((e) => {
                return (
                  <Dropdown.Item
                    key={e._id}
                    eventKey={e._id}
                    onSelect={(eventKey) => {
                      selectSite(eventKey);
                    }}
                  >
                    {e.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}
        <button
          className="btn btn-block btn-primary mt-3"
          disabled={loading | !selectedSite}
          onClick={acceptSelectedSite}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Sites;
