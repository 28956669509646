import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import authService from "../../services/auth.service";
import ticketService from "../../services/ticket.service";
import timeService from "../../services/time.service";
import siteService from "../../services/site.service";

const Time = () => {
  const user = authService.getCurrentUser();
  const site = siteService.getActiveSite();
  const [activeTicket, setActiveTicket] = useState(null);
  const [timeRecord, setTimeRecord] = useState(null);
  const [timeType, setTimeType] = useState("");
  const [error, setError] = useState(null);
  const history = useHistory();

  // Ensure user logged in
  useEffect(() => {
    if (!user) {
      console.log("no user logged in");
      history.push("/login");
    }
  });

  // Get the active ticket if one exists
  useEffect(() => {
    const getTicket = async () => {
      const ticket = await ticketService.getActiveWorkRecord();
      if (ticket) {
        setActiveTicket(ticket);
      } else {
        console.log("No active ticket");
      }
    };
    getTicket().catch(console.error);
  }, []);

  // Get the active time record if one exists
  useEffect(() => {
    const getTime = async () => {
      const ticket = await timeService.getActiveTimeRecord(user.id);
      if (ticket) {
        setTimeRecord(ticket);
      } else {
        console.log("no time record found");
      }
    };
    getTime().catch(console.error);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Set the time type per the active time record
  useEffect(() => {
    if (timeRecord) setTimeType(timeRecord.timeType);
    else setTimeType("");
  }, [timeRecord]);

  const clockIn = (type) => {
    const clockIn = async (type) => {
      const timeRecord = await timeService.clockIn(
        user.username,
        site.name,
        site._id,
        activeTicket.ticketName,
        user.id,
        activeTicket._id,
        type
      );
      setTimeRecord(timeRecord);
      setError(null);
    };

    clockIn(type)
      .then(() => {
        if (type === "service") history.push("/tags");
      })
      .catch((err) => {
        setError("Could not clock in. Check network");
        console.log(err);
      });
  };

  const _clockOut = () => {
    const clockOut = async (user) => {
      await timeService.clockOut(user.id);
      setTimeRecord(null);
      setError(null);
    };

    clockOut(user).catch((err) => {
      setError("Could not clock out. Check network");
      console.log(err);
    });
  };

  const clockInTravel = () => {
    clockIn("travel");
  };

  const clockInService = () => {
    clockIn("service");
  };

  const clockInBreak = () => {
    clockIn("break");
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <h4 className="card-title text-center">Time Record</h4>
        {/* {loading && <div>Loading...</div>} */}
        {error && <div className="alert alert-danger">{error}</div>}
        {timeRecord && (
          <div>
            {/* <div className="card-body row">{timeRecord.timeType}</div>  */}
            <div className="card-body row">
              Started: {Date(timeRecord.startTime)}
            </div>
          </div>
        )}
        <div>
          <button
            onClick={timeType !== "travel" ? clockInTravel : undefined}
            // className={ timeType === "travel" ? "btn btn-block btn-primary btn-lg active mt-3" : "btn btn-block btn-primary mt-3" }
            className={
              timeType === "travel"
                ? "btn btn-block btn-primary mt-3"
                : "btn btn-block btn-outline-primary mt-3"
            }
            // disabled={timeType === "travel"}
          >
            Travel
          </button>

          <button
            onClick={timeType !== "service" ? clockInService : undefined}
            className={
              timeType === "service"
                ? "btn btn-block btn-primary mt-3"
                : "btn btn-block btn-outline-primary mt-3"
            }
            // disabled={timeType === "service"}
          >
            Service
          </button>

          <button
            onClick={timeType !== "break" ? clockInBreak : undefined}
            className={
              timeType === "break"
                ? "btn btn-block btn-primary mt-3"
                : "btn btn-block btn-outline-primary mt-3"
            }
            // disabled={timeType === "break"}
          >
            Break
          </button>

          <div className="btn-group d-flex" role="group" aria-label="...">
            <button
              onClick={_clockOut}
              className="btn btn-block btn-outline-danger mt-3"
              disabled={!timeRecord}
            >
              Clock Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Time;
