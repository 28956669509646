import { useState, useEffect } from 'react';
import authHeader from "./auth-header";
import { API_URL } from "../App"

const useFetch = (query) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortCont = new window.AbortController();

    setTimeout(() => {
      fetch(API_URL + query, { signal: abortCont.signal, headers: authHeader() })
      .then(res => {
        if (!res.ok) { // error coming back from server
          console.log("useFetch error: " + res)
          throw Error('Could not fetch the data for that resource: ' + res.status);
        } 
        return res.json();
      })
      .then(data => {
        setLoading(false);
        setData(data);
        setError(null);
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          console.log('fetch aborted')
        } else {
          // auto catches network / connection error
          setLoading(false);
          setError(err.message);
        }
      })
    }, 1);

    // abort the fetch
    return () => abortCont.abort();
  }, [query])

  return { data, loading, error };
}

export default useFetch;