import React from 'react';
import { useHistory } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import AuthService from "../services/auth.service";

const Navbar1 = ({ online, logOut }) => {
  const history = useHistory();
  const currentUser = AuthService.getCurrentUser()
  const showAdminBoard = currentUser && currentUser.type === "admin"

  return (
    <Navbar bg="light" expand="md">
    <Container className="fluid">
        <Navbar.Brand className={online ? "text-success" : "text-warning"} href="/">Zimmerman Industrial</Navbar.Brand>

        {currentUser && <div>
        <Navbar.Toggle aria-controls="user-navbar-nav" />
        <Navbar.Collapse id="user-navbar-nav">
          <Nav className="me-auto">
              <Nav.Link className="text-right" href="" onClick={() => history.push("/sites")}>Sites</Nav.Link>
              <Nav.Link className="text-right" href="" onClick={() => history.push("/tickets")}>Tickets</Nav.Link>
              <Nav.Link className="text-right" href="" onClick={() => history.push("/time")}>Time</Nav.Link>
              <Nav.Link className="text-right" href="" onClick={() => history.push("/tags")}>Tags</Nav.Link>
              <Nav.Link className="text-right" href="" onClick={() => history.push("/issues")}>Issues</Nav.Link>
              <Nav.Link className="text-right" href="" onClick={() => history.push("/upload")}>Upload Records</Nav.Link>
              {showAdminBoard && <Nav.Link className="text-right" href="" onClick={() => history.push("/admin")}>Admin</Nav.Link>}              
              <NavDropdown className="text-right" title="User" id="user-nav-dropdown">
                    <NavDropdown.Item className="text-right" href="" onClick={() => history.push("/profile")}>{currentUser.username}</NavDropdown.Item>
                    <NavDropdown.Item className="text-right" href="" onClick={logOut}>Log Out</NavDropdown.Item>
              </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        </div>}
    </Container>
    </Navbar>
  );
}
export default Navbar1;