import Dexie from "dexie";
import { API_URL } from "../App"
import authHeader from './auth-header';

const db = new Dexie("IssuesDatabase");
db.version(1).stores({
  serviceIssues: "_id, tagId, issueName",
  siteIssues: "_id, tagId, tagNumber, issueName",
});

class IssueService {

  async clearDBs() {
    try {
      console.log("clearing issues database")
      await db.siteIssues.clear()
      await db.serviceIssues.clear()
      return true
      
    } catch (error) {
      console.log("error in issue.service.clearDBs")
      console.log(error)
    }
  }

  async uploadAllIssues() {
    const serviceIssues = await db.serviceIssues.toArray();
    const siteIssues = await db.siteIssues.toArray();

    if (serviceIssues && serviceIssues.length > 0) {
      await Promise.all(
        serviceIssues.map(async (r) => {
          const URI = 'serviceissue/id/' + r._id
          await this.upsertOnServer(URI, r);
        })
      );
    }

    if (siteIssues && siteIssues.length > 0) {
      await Promise.all(
        siteIssues.map(async (r) => {
          const URI = 'siteissue/id/' + r._id
          await this.upsertOnServer(URI, r);
        })
      );
    }

    return "done uploading issues"
  }

  async fetchSiteIssues(siteId) {  
    fetch(API_URL + "siteissues/site/" + siteId, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"]        
      },
    })
    .then((res) => {
      const data = res.json()
      return data
    })
    .then((data) => {
      db.siteIssues.clear()
      return data.siteIssues
    })
    .then((records) => {
      db.siteIssues.bulkAdd(records)
    })
    .catch(error => {
      console.log("error in fetchSiteIssues")
      throw error
    });
  }
  
  async fetchServiceIssues(ticketId) {  
    console.log(ticketId)
    const PATH = ticketId ? `serviceissues/ticket/${ticketId}` : `serviceissues`

    fetch(API_URL + PATH, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"]        
      },
    })
    .then((res) => {
      const data = res.json()
      return data
    })
    .then((data) => {
      db.serviceIssues.clear()
      return data.serviceIssues
    })
    .then((issues) => {
      console.log("adding service issues")
      console.log(issues)
      db.serviceIssues.bulkAdd(issues)
      console.log("service issues added")
    })
    .catch(error => {
      console.log("error in fetchTags")
      throw error
    });
  }

  async getAllSiteIssues() {
    return await db.siteIssues.toArray()
  }

  async getAllServiceIssues() {
    return await db.serviceIssues.toArray()
  }

  async getServiceIssuesByTag(tagId) {
    try {
      const result = await db.serviceIssues
        .where({tagId: tagId})
        .toArray();
      return result
    } catch (error) {
      console.log("getServiceIssuesByTag failed for tagId " + tagId)
      console.log(error)
    }
  }

  async getSiteIssuesByTag(tagId) {
    try {
      const result = await db.siteIssues
        .where({tagId: tagId})
        .toArray();
      return result
    } catch (error) {
      console.log(error)
    }
  }

  async getServiceIssuebyId(issueId) {
    return await db.serviceIssues.get(issueId)
  }

  async getSiteIssuebyId(issueId) {
    return await db.siteIssues.get(issueId)
  }

  async newServiceIssue(issue) {
    try {
      await db.serviceIssues.add(issue)
      // await this.addOnServer(URI, issue)
    } catch (error) {
      console.log("error in issue.service newServiceIssue")
      throw error
    } 
  }

  async newSiteIssue(issue) {
    // const URI = 'siteissue'
    try {
      await db.siteIssues.add(issue)
    } catch (error) {
      console.log("error in issue.service newSiteIssue")
      throw error
    } 
  }

  async updateServiceIssue(issue) {
    try {
      await db.serviceIssues.put(issue)
    } catch (error) {
      console.log("error in issue.service updateServiceIssue")
      throw error
    }
  }
  
  async updateSiteIssue(issue) {
    try {
      await db.siteIssues.put(issue)
    } catch (error) {
      console.log("error in issue.service updateServiceIssue")
      throw error
    }
  }

  async deleteServiceIssue(issueId) {
    const URI = 'serviceissue/id/'
    try {
      await db.serviceIssues.delete(issueId)
      this.deleteOnServer(URI, issueId)
    } catch (error) {
      console.log("error in issue.service deleteServiceIssue")
      throw error
    }
  }

  async deleteSiteIssue(issueId) {
    const URI = 'siteissue/id/'
    try {
      await db.siteIssues.delete(issueId)
      this.deleteOnServer(URI, issueId)
    } catch (error) {
      console.log("error in issue.service deleteServiceIssue")
      throw error
    } 
  }

  async addOnServer(uri, record) {
    return fetch(API_URL + uri, {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"]
      },
      "body": JSON.stringify(record)
    })
    .then(res => {
      if (!res.ok) { // error coming back from server
        console.log("issue.service addOnServer error: " + res)
        throw Error('Could not upload record to server: ' + res.status);
      } 
      return res.json();
    })
  }
  
  async upsertOnServer(uri, record) {
    return fetch(API_URL + uri, {
      "method": "PATCH",
      "headers": {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"]
      },
      "body": JSON.stringify(record)
    })
    .then(res => {
      if (!res.ok) { // error coming back from server
        console.log("issue.service updateOnServer error: " + res)
        throw Error('Could not update issue record: ' + res.status);
      } 
      return res.json();
    })
  }

  async deleteOnServer(uri, record) {
    fetch(API_URL + uri + record._id, {
      "method": "DELETE",
      "headers": {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"]        
      },
    })
    .then(response => {
      console.log(response);
    })
    .catch(err => {
      console.log("issue.service deleteOnServer error");
      throw err
    });
  }
}

export default new IssueService ();
