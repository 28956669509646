import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import CustomMenu from "../utils/custommenu";
import { useState, useEffect } from "react";

import authService from "../../services/auth.service";
import siteService from "../../services/site.service";
import issueService from "../../services/issue.service";

const IssuesBySite = () => {
  const history = useHistory();
  const user = authService.getCurrentUser();
  const site = siteService.getActiveSite();
  const [siteIssues, setSiteIssues] = useState([]);
  const [serviceIssues, setServiceIssues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Ensure user logged in
  useEffect(() => {
    if (!user) history.push("/login");
  });

  // Handle user login expired
  useEffect(() => {
    if (error && error.includes("401")) {
      //login expired
      authService.logout();
      history.push("/login");
    }
  }, [error, history]);

  // Ensure selected site
  useEffect(() => {
    if (!site) history.push("/sites");
  });

  // Get the data
  useEffect(() => {
    setLoading(true);
    const fetchSite = async () => {
      const siteIssues = await issueService.getAllSiteIssues();
      const serviceIssues = await issueService.getAllServiceIssues();

      if (siteIssues) {
        setSiteIssues(siteIssues);
      }
      if (serviceIssues) {
        setServiceIssues(serviceIssues);
      }
    };

    fetchSite()
      .then(() => setLoading(false))
      .catch((error) => {
        setError("Error loading data");
        console.log(error);
      });
  }, []); 

  const goToSiteIssue = (issueId) => {
    history.push("/siteissuedetails/" + issueId);
  };

  const goToServiceIssue = (issueId) => {
    history.push("/serviceissuedetails/" + issueId);
  };

  const cancel = () => {
    history.go(-1);
  };

  const SiteIssues = () => {
    return (
      <>
        <Dropdown>
          <Dropdown.Toggle
            className="btn btn-block btn-primary mt-3"
            id="tagsTodo-dropdown"
          >
            Site Issues
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenu}>
            {siteIssues.map((e) => {
              return (
                <Dropdown.Item
                  key={e._id}
                  eventKey={e._id}
                  onSelect={(eventKey) => {
                    goToSiteIssue(eventKey);
                  }}
                  variant={e.resolved ? "danger" : "secondary"}
                >
                  {`${e.issueName} ${e.machineName}`}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const ServiceIssues = () => {
    return (
      <>
        <Dropdown>
          <Dropdown.Toggle
            className="btn btn-block btn-primary mt-3"
            id="tagsTodo-dropdown"
          >
            Service Issues
          </Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenu}>
            {serviceIssues.map((e) => {
              return (
                <Dropdown.Item
                  key={e._id}
                  eventKey={e._id}
                  onSelect={(eventKey) => {
                    goToServiceIssue(eventKey);
                  }}
                  variant={e.resolved ? "danger" : "secondary"}
                >
                  {`${e.machineName} ${e.servicePart}, ${e.issueName}`}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {loading && <div>Loading...</div>}
        {error && <div className="alert alert-danger">{error}</div>}
        {!loading && !error && site && (
          <div>
            <h4 className="card-title text-center">{site.name}</h4>
            <SiteIssues />
            <ServiceIssues />
            <button
              className="btn btn-block btn-outline-danger mt-3"
              onClick={() => {
                history.push({ pathname: "/newsiteissue" });
              }}
            >
              New Site Issue
            </button>
            <button
              onClick={cancel}
              className="btn btn-block btn-secondary mt-3"
            >
              Back
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default IssuesBySite;
