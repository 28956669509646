import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, CloseButton } from "react-bootstrap";
import Resizer from "react-image-file-resizer";

import authService from "../../services/auth.service";
import issueService from "../../services/issue.service";

const ServiceIssueDetails = () => {
  const { issueId } = useParams();
  const history = useHistory();
  const user = authService.getCurrentUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // From Admin - formData
  const [issue, setIssue] = useState({
    userName: "",
    siteName: "",
    machineName: "",
    servicePart: "",
    issueName: "",
    issueDetails: "",
    issuePriority: "",
    resolved: false,
    released: false,
    timestamp: 0,
    tagId: "",
    tagNumber: 0,
    siteId: "",
    images: [],
  });

  // Ensure user logged in
  useEffect(() => {
    if (!user) history.push("/login");
  });

  // Load the issue
  useEffect(() => {
    setLoading(true);
    issueService
      .getServiceIssuebyId(issueId)
      .then((issue) => {
        setIssue(issue);
      })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFieldChange = (e) => {
    console.log(issue);
    setIssue({
      ...issue,
      [e.target.name]: e.target.value,
    });
  };

  const cancel = () => {
    history.go(-1);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const saveImages = async (e) => {
    try {
      const file = e.target.files[0];
      const image = await resizeFile(file);

      setIssue({
        ...issue,
        images: [...issue.images, image],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteImage = (i) => {
    if (i > -1) {
      let imgs = issue.images;
      imgs.splice(i, 1);

      setIssue({
        ...issue,
        images: imgs,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    issueService
      .updateServiceIssue(issue)
      .then(() => {
        history.go(-1);
      })
      .catch((error) => {
        console.log(error);
        setError("Unable to update Issue");
      });
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {loading && <div>Loading...</div>}
        {error && <div className="alert alert-danger">{error}</div>}
        <h4 className="card-title text-center font-weight-bold">
          Update Issue
        </h4>

        <p className="card-text text-center font-weight-bold">
          Tag #{issue.tagNumber}
        </p>

        {issue.resolved && (
          <p className="card-text text-center font-weight-bold text-white bg-success">
            Resolved
          </p>
        )}

        <form id="issueform" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              name="issueName"
              placeholder="Issue name"
              value={issue.issueName}
              onChange={handleFieldChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              name="issueDetails"
              placeholder="Additional details (optional)"
              rows={3}
              value={issue.issueDetails}
              onChange={handleFieldChange}
            />
          </Form.Group>

          <select
            className="custom-select"
            placeholder="priority"
            name="issuePriority"
            value={issue.issuePriority}
            onChange={handleFieldChange}
          >
            <option value="critical">critical</option>
            <option value="important">important</option>
            <option value="routine">routine</option>
          </select>

          {issue.images.length > 0 && (
            <div>
              {issue.images.map((img, index) => (
                <div
                  key={index}
                  className="m-2 d-flex flex-column border rounded"
                >
                  <div className="text-end">
                    <CloseButton onClick={() => deleteImage(index)} />
                  </div>
                  <img
                    src={img}
                    alt=""
                    onClick={() => {
                      console.log("Ahh!");
                    }}
                  />
                </div>
              ))}
            </div>
          )}

          <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Label>Add Images</Form.Label>
            <Form.Control
              type="file"
              accept="image/*;capture=camera"
              onChange={(e) => saveImages(e)}
            />
          </Form.Group>

        </form>

        <div className="btn-group d-flex" role="group" aria-label="...">
          <button onClick={cancel} className="btn btn-block btn-secondary mt-3">
            Back
          </button>
          <button
            onClick={() => {
              setIssue({ ...issue, resolved: !issue.resolved });
            }}
            className="btn btn-block btn-success mt-3"
          >
            Resolved
          </button>
          <button
            type="submit"
            form="issueform"
            className="btn btn-block btn-primary mt-3"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceIssueDetails;
