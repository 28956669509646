import React, { Component } from "react";
import { withRouter, Switch, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Navbar1 from "./components/navbar";
import Login from "./components/users/login";
import Sites from "./components/sites/sites";
import Tickets from "./components/tickets/tickets";
import Time from "./components/time/time";
import Tags from "./components/tags/tags";
import Tag from "./components/tags/tag";

import IssuesBySite from "./components/issues/allissues";
import SiteIssuesByTag from "./components/issues/allsiteissues";
import ServiceIssuesByTag from "./components/issues/allserviceissues";
import SiteIssueDetails from "./components/issues/siteissuedetails";
import ServiceIssueDetails from "./components/issues/serviceissuedetails";
import NewSiteIssue from "./components/issues/newsiteissue";
import NewServiceIssue from "./components/issues/newserviceissue";

import UploadRecords from "./components/tickets/uploadrecords";
import Profile from "./components/users/profile.component";

export const API_URL = process.env.REACT_APP_API_URL;
console.log("API URL: ", API_URL)

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      online: null,
    };
  }

  componentDidMount() {
    this.setState({
      online: window.navigator.onLine,
    });
    window.addEventListener("offline", () => {
      this.setState({ online: false });
    });
    window.addEventListener("online", () => {
      this.setState({ online: true });
    });
  }

  logIn = () => {
    this.setState({ currentUser: AuthService.getCurrentUser() }, () => {
      console.log("logged in: " + this.state.currentUser.username);
    });
    this.props.history.push("/sites");
  };

  logOut = () => {
    AuthService.logout();
    this.props.history.push("/");
    console.log("logged out");
  };

  render() {
    return (
      <div>
        <Navbar1 logOut={this.logOut} online={this.state.online} />
        <Switch>
          <Route exact path="/login">
            <Login setUser={this.logIn} />
          </Route>
          <Route exact path={["/", "/home", "/sites"]}>
            <Sites />
          </Route>
          <Route exact path="/tickets">
            <Tickets />
          </Route>
          <Route exact path="/upload">
            <UploadRecords />
          </Route>

          <Route exact path="/time">
            <Time />
          </Route>
          <Route exact path="/tag/:tagId">
            <Tag />
          </Route>
          <Route exact path="/tags">
            <Tags />
          </Route>
          <Route exact path="/issues">
            <IssuesBySite />
          </Route>

          <Route exact path="/serviceissues/:tagId">
            <ServiceIssuesByTag />
          </Route>

          <Route exact path="/siteissues/:tagId">
            <SiteIssuesByTag />
          </Route>

          <Route exact path="/serviceissuedetails/:issueId">
            <ServiceIssueDetails />
          </Route>

          <Route exact path="/siteissuedetails/:issueId">
            <SiteIssueDetails />
          </Route>

          <Route exact path="/newserviceissue/:tagId">
            <NewServiceIssue />
          </Route>
          <Route exact path="/newsiteissue">
            <NewSiteIssue />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
