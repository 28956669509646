import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import CustomMenu from "../utils/custommenu";
import authService from "../../services/auth.service";
import ticketService from "../../services/ticket.service";
import tagService from "../../services/tag.service";
import timeService from "../../services/time.service";
import ProgressBar from "./progressbar";


const Tags = () => {
  const history = useHistory();
  const user = authService.getCurrentUser();
  
  const [startTime, setStartTime] = useState(0);
  const [ticket, setTicket] = useState(null);
  const [tagsToDo, setTagsToDo] = useState([]);
  const [tagsDone, setTagsDone] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Ensure user logged in
  useEffect(() => {
    if (!user) {
      console.log("no user logged in");
      history.push("/login");
    }
  });

  // Get the active work ticket, tags, etc
  useEffect(() => {
    setLoading(true);

    const getTicket = async () => {
      const ticket = await ticketService.getActiveWorkRecord();

      if (ticket) {
        setTicket(ticket);
      } else {
        console.log("No active ticket");
        history.push("/tickets");
      }

      const tagsToDo = await tagService.getDbTags(ticket.remainingTags);
      const tagsDone = await tagService.getDbTags(ticket.completedTags);

      if (tagsToDo) {
        setTagsToDo(tagsToDo);
      } else {
        console.log("no tagsToDo found");
      }

      if (tagsDone) {
        setTagsDone(tagsDone);
      } else {
        console.log("no tagsDone found");
      }
      setLoading(false);
    };

    getTicket().catch((error) => {
      console.log(error);
      setError("Error loading ticket and tags");
      setLoading(false);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Ensure active time record
  useEffect(() => {
    const timeRecord = timeService.getLocalTimeRecord();

    if (!timeRecord) {
      console.log("not clocked in");
      history.push("/time");
    } else {
      const startTime = new Date(timeRecord.startTime) 
      setStartTime(startTime)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Jump to a specific tag
  const setTag = (id) => {
    history.push("/tag/" + id);
  };

  // Undo a completed tag (delete service record)
  const deleteRecord = async (tagId) => {
    try {
      await tagService.deleteServiceRecord(tagId);
      await ticketService.removeServiceRecord(tagId);
      history.push("/tag/" + tagId);
    } catch (error) {
      console.log(error);
    }
  };

  // Return to tag
  const cancel = () => {
    history.go(-1);
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {loading && <div className="alert alert-info">Loading...</div>}
        {error && <div className="alert alert-danger">{error}</div>}
        {!tagsToDo && <h2 className="mx-auto">No tags loaded </h2>}
        {ticket && startTime && (
          <div>
            <h4 className="card-title text-center">{ticket.ticketName}</h4>
            <div className="text-center">
              <div>Started: { startTime.toLocaleTimeString("en-US") }</div>
            </div>

            <br></br>
            <div className="text-center font-weight-bold">Progress</div>
            <ProgressBar />

            <Dropdown>
              <Dropdown.Toggle
                className="btn btn-block btn-primary mt-3"
                id="tagsDone-dropdown"
              >
                Undo Completed
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                {tagsDone.slice(0).reverse().map((e) => {
                  return (
                    <Dropdown.Item
                      key={e._id}
                      eventKey={e._id}
                      onSelect={(eventKey) => {
                        deleteRecord(eventKey);
                      }}
                    >
                      {`${e.machine.name} ${e.servicePart}, Tag ${e.tagNumber}`}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle
                className="btn btn-block btn-primary mt-3"
                id="tagsTodo-dropdown"
              >
                Jump to Tag
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu}>
                {tagsToDo.map((e) => {
                  return (
                    <Dropdown.Item
                      key={e._id}
                      eventKey={e._id}
                      onSelect={(eventKey) => {
                        setTag(eventKey);
                      }}
                    >
                      {`${e.machine.name} ${e.servicePart}, Tag ${e.tagNumber}`}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>

            <button
              onClick={cancel}
              className="btn btn-block btn-secondary mt-3"
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tags;
