import { useEffect, useState } from "react";
import Progress from "../utils/progress";
import timeService from "../../services/time.service";
import ticketService from "../../services/ticket.service";

const ProgressBar = () => {
  const startTime = new Date(timeService.getLocalTimeRecord().startTime);
  const [elapsedTime, setElapsedTime] = useState(0);

  const [progress, setProgress] = useState({
    allotedTime: 0,
    tagsToDo: 0,
    tagsDone: 0,
    actualPercent: 0,
    expectedPercent: 0,
    timing: "",
    progressText: "",
  });

  // Gets called every second
  function updateTime() {
    const elapsed = ((Date.now() - startTime) / 60000).toFixed(1);
    setElapsedTime(elapsed);
  }

  // Get the active work ticket
  useEffect(() => {
    let interval = null;

    ticketService
      .getActiveWorkRecord()
      .then((ticket) => {
        const todo = ticket.remainingTags.length;
        const done = ticket.completedTags.length;

        setProgress({
          ...progress,
          allotedTime: ticket.allotedMinutes > 0 ? ticket.allotedMinutes : 1,
          tagsToDo: todo,
          tagsDone: done,
          actualPercent: Math.round((100 * done) / (done + todo + 1)),
        });
      })
      .then(() => {
        // Initialize the bar
        const elapsed = ((Date.now() - startTime) / 60000).toFixed(1);
        setElapsedTime(elapsed);
      })
      .then(() => {
        interval = setInterval(() => {
          updateTime();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      clearInterval(interval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Update the progress bar
  useEffect(() => {
    const expectedPercent = Math.round(
      (100 * elapsedTime) / progress.allotedTime
    );

    const timing = `${elapsedTime}/${progress.allotedTime} minutes`;
    const progressText = `${progress.tagsDone}/${
      progress.tagsDone + progress.tagsToDo
    } tags`;

    setProgress({
      ...progress,
      expectedPercent: expectedPercent,
      timing: timing,
      progressText: progressText,
    });
  }, [elapsedTime]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Progress
        expectedPercent={progress.expectedPercent}
        actualPercent={progress.actualPercent}
      />
      <div className="text-center text-muted">{`${progress.timing}, ${progress.progressText}`}</div>
    </>
  );
};

export default ProgressBar;
