import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Dropdown } from "react-bootstrap";
import CustomMenu from "../utils/custommenu";
import authService from "../../services/auth.service";
import siteService from "../../services/site.service";
import ticketService from "../../services/ticket.service";
import tagService from "../../services/tag.service";
import issueService from "../../services/issue.service";

const Tickets = () => {
  const user = authService.getCurrentUser();
  const site = siteService.getActiveSite();
  const [tickets, setTickets] = useState([]);
  const [workRecords, setWorkRecords] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const history = useHistory();

  // Ensure user logged in
  useEffect(() => {
    if (!user) history.push("/login");
  });

  // Handle user login expired
  useEffect(() => {
    if (error && error.includes("401")) {
      //login expired
      authService.logout();
      history.push("/login");
    }
  }, [error, history]);

  // Ensure selected site
  useEffect(() => {
    if (!site) history.push("/sites");
  });

  // Fetch the tickets & workrecords for the selected site
  useEffect(() => {
    const getTickets = async () => {
      setLoading(true);
      const tickets = await ticketService.fetchTickets();
      const workrecords = await ticketService.fetchWorkRecords();

      setLoading(false);
      setError(null);
      setTickets(tickets);
      setWorkRecords(workrecords);
    };

    getTickets().catch((error) => {
      setLoading(false);
      setError(error);
    });
  }, []);

  // Select Ticket Handler
  const selectTicket = (e) => {
    let ticket = tickets.find((x) => x._id === e);
    setSelectedRecord(null);
    setSelectedTicket(ticket);
  };

  // Select WorkRecord Handler
  const selectWorkRecord = (e) => {
    let workrecord = workRecords.find((x) => x._id === e);
    setSelectedTicket(null);
    setSelectedRecord(workrecord);
  };

  // Accept Selected Handler
  // Load ticket, tags, & issues
  const acceptSelected = () => {
    const getTicket = async () => {
      // Load the ticket
      if (selectedTicket) {
        await ticketService.newWorkRecord(selectedTicket);
        await tagService.fetchTagsByTicket(selectedTicket._id);
        return selectedTicket._id;
      } else if (selectedRecord) {
        await ticketService.continueWorkRecord(selectedRecord);
        await tagService.fetchTagsByTicket(selectedRecord.ticket._id);
        await tagService.fetchServiceRecords(selectedRecord._id);
        return selectedRecord.ticket._id;
      } else {
        console.log("No ticket or record to accept");
        return false;
      }
    };

    // Load the issues
    const getIssues = async (ticketId) => {
      return await issueService.fetchServiceIssues(ticketId);
    };

    getTicket()
      .then((ticketId) => {
        console.log(`TicketId: ${ticketId}`)
        if (ticketId) getIssues(ticketId);
      })
      .then(() => {
        history.push("/time");
      })
      .catch(console.error);
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {loading && <div className="alert alert-info">loading...</div>}
        {error && <div className="alert alert-danger">{error}</div>}
        {!selectedTicket && !selectedRecord && (
          <h2 className="mx-auto">Select a Ticket</h2>
        )}
        {selectedTicket && (
          <div>
            <h4 className="card-title text-center font-weight-bold">
              {selectedTicket.ticketName}
            </h4>
            <hr />
            <div className="card-body row">
              <ul className="list-group list-group-flush ">
                <li className="list-group-item font-weight-bold">
                  Ticket Type: {selectedTicket.type}
                </li>
                <li className="list-group-item">
                  Alloted Time:{" "}
                  {(selectedTicket.allotedMinutes / 60).toFixed(2)} Hrs
                </li>
              </ul>
            </div>
          </div>
        )}
        {selectedRecord && (
          <div>
            <h4 className="card-title text-center font-weight-bold">
              {selectedRecord.ticketName}
            </h4>
            <hr />
            <div className="card-body row">
              <ul className="list-group list-group-flush ">
                <li className="list-group-item">
                  Alloted Time:{" "}
                  {(selectedRecord.allotedMinutes / 60).toFixed(2)} Hrs
                </li>
              </ul>
            </div>
          </div>
        )}
        {!error && tickets.length > 0 && (
          <div>
            <Dropdown>
              <Dropdown.Toggle
                className="btn btn-block btn-primary mt-3"
                id="tickets-dropdown"
              >
                Select Ticket
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                {tickets.map((e) => {
                  return (
                    <Dropdown.Item
                      key={e._id}
                      eventKey={e._id}
                      onSelect={(eventKey) => {
                        selectTicket(eventKey);
                      }}
                    >
                      {e.ticketName}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        {!error && workRecords.length > 0 && (
          <div>
            <Dropdown>
              <Dropdown.Toggle
                className="btn btn-block btn-primary mt-3"
                id="workrecords-dropdown"
              >
                Continue Ticket
              </Dropdown.Toggle>

              <Dropdown.Menu as={CustomMenu}>
                {workRecords.map((e) => {
                  return (
                    <Dropdown.Item
                      key={e._id}
                      eventKey={e._id}
                      onSelect={(eventKey) => {
                        selectWorkRecord(eventKey);
                      }}
                    >
                      {e.ticketName}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        <button
          className="btn btn-block btn-primary mt-3"
          disabled={!selectedTicket && !selectedRecord}
          onClick={acceptSelected}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Tickets;
