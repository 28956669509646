import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Resizer from "react-image-file-resizer";
import { v4 as uuidv4 } from "uuid";

import authService from "../../services/auth.service";
import siteService from "../../services/site.service";
import tagService from "../../services/tag.service";
import issueService from "../../services/issue.service";

const NewServiceIssue = () => {
  const { tagId } = useParams();
  const history = useHistory();
  const user = authService.getCurrentUser();
  const site = siteService.getActiveSite();

  const [issueName, setIssueName] = useState("");
  const [issueDetails, setIssueDetails] = useState("");
  const [issuePriority, setIssuePriority] = useState("important");
  const [tag, setTag] = useState(null);
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);

  // Ensure user logged in
  useEffect(() => {
    if (!user) history.push("/login");
  });

  // Ensure selected site
  useEffect(() => {
    if (!site) history.push("/sites");
  });

  // Get the tag
  useEffect(() => {
    try {
      tagService.getTag(tagId)
      .then((tag) => {
        setTag(tag);
      });
    } catch (error) {
      console.log(error)
      setError("Tag not found")
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const cancel = () => {
    history.go(-1);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const saveImages = async (e) => {
    try {
      const file = e.target.files[0];
      const image = await resizeFile(file);
      // console.log(image);
      setImages([image, ...images]);
      console.log(images);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let issueData = {
      _id: uuidv4(),
      userName: user.username,
      siteName: site.name,
      machineName: tag.machine.name,
      servicePart: tag.servicePart,
      issueName: issueName,
      issueDetails: issueDetails,
      issuePriority: issuePriority,
      resolved: false,
      timestamp: Date.now(),
      tagId: tagId,
      tagNumber: tag.tagNumber,
      siteId: site._id,
      images: images
    };
    try {
      issueService.newServiceIssue(issueData)
      .then(() => {
        history.go(-1);
      })
    } catch (error) {
      console.log(error);
      setError("Unable to save issue");
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {error && <div className="alert alert-danger">{error}</div>}
        <h4 className="card-title text-center font-weight-bold">
          New Service Issue
        </h4>
        <p className="card-text text-center font-weight-bold">
          Tag #{tag && tag.tagNumber}
        </p>

        <form id="issueform" onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder="Issue name"
              type="text"
              required
              value={issueName}
              onChange={(e) => setIssueName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              placeholder="Additional details (optional)"
              rows={3}
              value={issueDetails}
              onChange={(e) => setIssueDetails(e.target.value)}
            />
          </Form.Group>

          <select
            className="custom-select"
            value={issuePriority}
            onChange={(e) => setIssuePriority(e.target.value)}
          >
            <option value="critical">critical</option>
            <option value="important">important</option>
            <option value="routine">routine</option>
          </select>

          <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Label>Add Images</Form.Label>
            <Form.Control
              type="file"
              accept="image/*;capture=camera"
              onChange={(e) => saveImages(e)}
            />
          </Form.Group>

          {images.length > 0 && (
            <div>
              {images.map((i) => (
                <img src={i} alt="" />
              ))}
            </div>
          )}
        </form>

        <div className="btn-group d-flex" role="group" aria-label="...">
          <button onClick={cancel} className="btn btn-block btn-secondary mt-3">
            Cancel
          </button>
          <button
            type="submit"
            form="issueform"
            className="btn btn-block btn-primary mt-3"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewServiceIssue;
