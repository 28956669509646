class SiteService {

  setActiveSite(site) {
      localStorage.setItem("site", JSON.stringify(site));
  }

  getActiveSite() {
    return JSON.parse(localStorage.getItem('site'));
  }

  clearActiveSite() {
    localStorage.removeItem("site");
  }
}

export default new SiteService();
