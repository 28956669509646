import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import authService from "../../services/auth.service";
import ticketService from "../../services/ticket.service";
import tagService from "../../services/tag.service";
import issueService from "../../services/issue.service";
import ProgressBar from "./progressbar";

const Tag = () => {
  const { tagId } = useParams();
  const history = useHistory();
  const user = authService.getCurrentUser();

  const [tag, setTag] = useState(null);
  const [serviceIssues, setServiceIssues] = useState([]);
  const [siteIssues, setSiteIssues] = useState([]);
  const [ticket, setTicket] = useState(null);
  const [tagsToDo, setTagsToDo] = useState([]);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Ensure user logged in
  useEffect(() => {
    if (!user) {
      console.log("no user logged in");
      history.push("/login");
    }
  });

  // Get the active work ticket and tagsTodo
  useEffect(() => {
    const getTicket = async () => {
      setLoading(true);
      const workRecord = await ticketService.getActiveWorkRecord();
      const tagsToDo = await tagService.getDbTags(workRecord.remainingTags);

      if (workRecord && !workRecord.complete) {
        setTicket(workRecord);
      } else if (workRecord.complete) {
        history.push("/ticketcomplete");
      } else {
        console.log("No active ticket");
        history.push("/ticket");
      }

      if (tagsToDo) {
        setTagsToDo(tagsToDo);
      } else {
        console.log("no tags found");
      }
    };

    getTicket()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError("Error loading data");
        setLoading(false);
      });
  }, [tagId, history]);

  // Get the active tag and issues
  useEffect(() => {
    const getTag = async (tagId) => {
      setLoading(true);
      setChecked(false);
      const tag = await tagService.getTag(tagId);
      const serviceIssues = await issueService.getServiceIssuesByTag(tagId);
      const siteIssues = await issueService.getSiteIssuesByTag(tagId);

      if (tag) {
        setTag(tag);
      } else {
        throw Error("Tag not found");
      }

      if (serviceIssues) {
        setServiceIssues(serviceIssues);
      } else {
        console.log("no service issues");
      }

      if (siteIssues) {
        setSiteIssues(siteIssues);
      } else {
        console.log("no site issues");
      }
    };

    getTag(tagId)
      .then(() => {
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
        setLoading(false);
      });
  }, [tagId]);

  const toggleChecked = () => {
    setChecked(!checked);
  };

  const tagCompleted = async () => {
    try {
      // Create service record
      const serviceRecordId = await tagService.newServiceRecord(tag, ticket);

      // Update workTicket
      await ticketService.addServiceRecord(tag._id, serviceRecordId);

      // get the next tag
      nextTag();
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const nextTag = () => {
    if (tagsToDo.length > 1) {
      let i = tagsToDo.findIndex((t) => t._id === tag._id) + 1;
      i = i > tagsToDo.length - 1 ? 0 : i;
      let next = tagsToDo[i];
      history.push("/tag/" + next._id);
    } else {
      // That was the last tag
      history.push("/upload");
    }
  };

  const previousTag = () => {
    let i = tagsToDo.findIndex((t) => t._id === tag._id) - 1;
    i = i < 0 ? tagsToDo.length - 1 : i;
    let next = tagsToDo[i];
    history.push("/tag/" + next._id);
  };

  const cardClass =
    tag && tag.active
      ? "card card-container"
      : "card card-container bg-secondary text-light";

  const SiteIssueAlert = () => {
    return (
      <button
        className="btn btn-block btn-danger mt-3"
        onClick={() => history.push({ pathname: "/siteissues/" + tagId })}
      >
        Site Issue
      </button>
    );
  };

  return (
    <div className="col-md-12" key={tagId}>
      <div className={cardClass}>
        {loading && <div className="alert alert-info">Loading...</div>}
        {error && <div className="alert alert-danger">{error}</div>}
        {siteIssues.length > 0 && <SiteIssueAlert />}
        {tag && (
          <div>
            <div className="card-body">
              <h4 className="card-title text-center font-weight-bold">
                Tag #{tag.tagNumber}
              </h4>
              {/* <Timer preset={percent}  key={tagId}/> */}
              {!tag.active && (
                <div className="text-center font-weight-bold">Inactive</div>
              )}
              <hr />
              <dl className="list-group list-group-flush">
                <dd className="col">LOTO Location</dd>
                <dt className="col border-bottom">{tag.machine.loto}</dt>
                <dd className="col mt-2">Machine Name</dd>
                <dt className="col border-bottom">{tag.machine.name}</dt>
                <dd className="col mt-2">Service Part</dd>
                <dt className="col border-bottom">{tag.servicePart}</dt>
                <dd className="col mt-2">Service Task</dd>
                <dt className="col border-bottom">{tag.serviceTask}</dt>

                {/* Display custom fields */}
                {tag.customFields.length > 0 && (
                  <div>
                    {tag.customFields.map(
                      (f) =>
                        f.displayed && (
                          <div key={f._id}>
                            <dd className="col mt-2">{f.fieldLabel}</dd>
                            <dt
                              className="col border-bottom"
                              style={{ color: f.color }}
                            >
                              {f.fieldValue}
                            </dt>
                          </div>
                        )
                    )}
                  </div>
                )}
                {/* <dd className="col mt-2">Time alloted</dd>
                <dt className="col border-bottom">
                  {tag.allotedMinutes} minutes
                </dt> */}
              </dl>
            </div>
            <div className="btn-group d-flex" role="group" aria-label="...">
              <button
                className="btn btn-block btn-primary mt-3"
                onClick={previousTag}
              >
                &lt;
              </button>
              <button
                className="btn btn-block btn-primary mt-3"
                onClick={() => {
                  history.push({ pathname: "/tags" });
                }}
              >
                Ticket
              </button>
              <button
                className="btn btn-block btn-primary mt-3"
                onClick={nextTag}
              >
                &gt;
              </button>
            </div>
            <div className="btn-group d-flex" role="group" aria-label="...">
              <button
                className={
                  serviceIssues.length > 0
                    ? "btn btn-block btn-danger mt-3"
                    : "btn btn-block btn-outline-danger mt-3"
                }
                disabled={!tag.active}
                onClick={() => {
                  history.push({ pathname: "/serviceissues/" + tagId });
                }}
              >
                Issues
              </button>
              <button
                className={
                  checked
                    ? "btn btn-block btn-success mt-3"
                    : "btn btn-block btn-outline-success mt-3"
                }
                disabled={!tag.active}
                onClick={toggleChecked}
              >
                Check
              </button>
              <button
                className="btn btn-block btn-primary mt-3"
                disabled={!checked && tag.active}
                onClick={tagCompleted}
              >
                Done
              </button>
            </div>
            <br />
            <ProgressBar />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tag;
