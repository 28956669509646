import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import issueService from "../../services/issue.service";
import tagService from "../../services/tag.service";
import ticketService from "../../services/ticket.service";

const UploadRecords = () => {
  const history = useHistory();
  const [workRecord, setWorkRecord] = useState(null);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false)
  const [uploadComplete, setUploadComplete] = useState(false);
  const [error, setError] = useState(null);

  // Get the active work ticket and tagsTodo
  useEffect(() => {
    const getTicket = async () => {
      setLoading(true);
      const workRecord = await ticketService.getActiveWorkRecord();

      setComplete(workRecord && workRecord.complete)

      if (workRecord) {
        setWorkRecord(workRecord);
      } else {
        console.log("No active ticket");
        // history.push("/ticket");
      }
    };

    getTicket()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError("Error loading data");
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleTicketComplete = async () => {
    let completeStatus = await ticketService.toggleWorkRecordComplete(workRecord)
    setComplete(completeStatus)
  }

  const uploadRecords = () => {
    tagService
      .uploadServiceRecords()
      .then(() => {
        console.log("uploading all issues")
        return issueService.uploadAllIssues();
      })
      .then((res) => {
        console.log(res)
        return ticketService.uploadWorkRecord();
      })
      .then(() => {
        tagService.clearDBs();
      })
      .then(() => {
        issueService.clearDBs();
      })
      .then(() => {
        ticketService.clearDBs();
      })
      .then(() => {
        setUploadComplete(true)
      })
      .catch(error => {
        setError("Unable to upload tags. Check connection and try again.")
      })
  };

  const gotoSites = () => {
    history.push("/sites");
  }

  const reviewTicket = () => {
    history.push("/tags");
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        {loading && <div className="alert alert-info">Loading...</div>}
        {uploadComplete && <div className="alert alert-success">Upload Complete</div>}
        {error && <div className="alert alert-danger">{error}</div>}
        {!workRecord && (
          <div className="alert alert-danger">No Active Ticket</div>
        )}
        {workRecord && (
          <div>
            <h4 className="card-title text-center font-weight-bold">
              {workRecord.ticketName} Ticket Complete!
            </h4>
            <button
              className="btn btn-block btn-primary mt-3"
              onClick={reviewTicket}
              disabled={uploadComplete}
            >
              Back
            </button>
            <button
              className="btn btn-block btn-primary mt-3"
              onClick={toggleTicketComplete}
              disabled={uploadComplete}
            >
              {complete ? "Mark Ticket Not Complete" : "Mark Ticket Complete"}
            </button>
            {!uploadComplete && <button
              className="btn btn-block btn-primary mt-3"
              onClick={uploadRecords}
            >
              Upload Records
            </button>}

            {uploadComplete && <button
              className="btn btn-block btn-primary mt-3"
              onClick={gotoSites}
            >
              Continue
            </button>}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadRecords;
