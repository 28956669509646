import Dexie from "dexie";
import { API_URL } from "../App"
import authHeader from './auth-header';
import authService from "./auth.service";
import siteService from "./site.service";
import tagService from "./tag.service";
import issueService from "./issue.service";

const db = new Dexie("TicketsDatabase");
db.version(1).stores({
  workrecord: "_id",
});

class TicketService {

  async clearDBs() {
    try {
      console.log("clearing ticket database")
      await db.workrecord.clear()
      return true
      
    } catch (error) {
      console.log("error in ticket.service.clearDBs")
      console.log(error)
    }
  }

  async uploadWorkRecord() {
    const activeRecord = await db.workrecord.toArray();
    const siteIssues = await issueService.getAllSiteIssues()
    const serviceIssues = await issueService.getAllServiceIssues()

    if (!activeRecord[0]) throw new Error("No record to upload")

    const record = {
      ...activeRecord[0],
      siteIssues: siteIssues,
      serviceIssues: serviceIssues
    }

    return await this.updateOnServer(record)
  }

  // Fetch the tickets for the active site
  async fetchTickets() {
    const site = await siteService.getActiveSite()

    const filters = JSON.stringify({
      sites: site._id,
    });

    return fetch(API_URL + `tickets?filters=${filters}`, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"]        
      },
    })
    .then((res) => {
      const data = res.json()
      return data
    })
    .catch(error => {
      console.log("fetchTickets error")
      throw error
    })
  }

  // Fetch open work records for the active site
  async fetchWorkRecords() {
    const site = await siteService.getActiveSite()

    const filters = JSON.stringify({
      sites: site._id,
      complete: false
    });

    return fetch(API_URL + `workrecords?filters=${filters}`, {  
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"]        
      },
    })
    .then((res) => {
      const data = res.json()
      return data
    })
    .catch(error => {
      console.log("fetchWorkRecords error")
      throw error
    })
  }

  async newWorkRecord(ticket) {
    if (!ticket) {
      const msg = 'No ticket!'
      console.log(msg)
      throw Error(msg)
    }

    const record = {
      site: ticket.site._id,
      user: authService.getCurrentUser().id,
      ticket: ticket._id,
      ticketName: ticket.ticketName,
      allotedMinutes: ticket.allotedMinutes,
      timestamp: Date.now(),
      complete: false,
      serviceRecords: [],
      completedTags: [],
      remainingTags: ticket.tags,
    };

    return fetch(API_URL + "workrecord", {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"]
      },
      "body": JSON.stringify(record)
    })
    .then(res => {
      if (!res.ok) { // error coming back from server
        console.log("createWorkRecord error: " + res)
        throw Error('Could not create Work Ticket: ' + res.status);
      } 
      return res.json();
    })
    .then((data) => {
      db.workrecord.clear()
      return data
    })
    .then((data) => {
      db.workrecord.add(data)
    })
    .then(() => {
      return true
    })
    .catch(err => {
      console.log("newWorkRecord error: " + err);
      throw err
    });
  }

  async addServiceRecord(tagId, serviceRecordId) {
    try {
      const record = await this.getActiveWorkRecord()
      record.completedTags = [...record.completedTags, tagId]
      record.serviceRecords = [...record.serviceRecords, serviceRecordId]
      record.remainingTags = record.remainingTags.filter(t => t !== tagId)

      if (record.remainingTags.length === 0) {
        record.complete = true
      }

      await db.workrecord.put(record)
      // await this.updateOnServer(record)

    } catch (error) {
      console.log("error in ticket.service addServiceRecord")
      throw error
    }
  }

  async removeServiceRecord(tagId) {
    try {
      const record = await this.getActiveWorkRecord()
      let serviceRecords = await tagService.getDbRecords()
      serviceRecords = serviceRecords.map(r => r.tagId)

      record.remainingTags = [tagId, ...record.remainingTags]
      record.completedTags = record.completedTags.filter(t => t !== tagId)
      record.serviceRecords = serviceRecords.filter(t => t !== tagId)

      await db.workrecord.put(record)

    } catch (error) {
      console.log("error in ticket.service removeServiceRecord")
      throw error
    }
  }

  async continueWorkRecord(workrecord) {
    if (!workrecord) {
      const msg = 'No work record to continue'
      console.log(msg)
      throw Error(msg)
    }

    return db.workrecord.clear()
    .then(() => {
      db.workrecord.add(workrecord);
    })
    .then(() => {
      return true
    })
    .catch(err => {
      console.log("continueWorkRecord error: " + err);
      throw err
    });
  }

  async toggleWorkRecordComplete(workrecord) {
    let record = await db.workrecord.get(workrecord._id)
    record.complete = !record.complete;
    await db.workrecord.put(record);
    return record.complete
  }

  async getActiveWorkRecord() {
    const activeRecord = await db.workrecord.toArray();
    return activeRecord ? activeRecord[0] : null;
  }

  async updateOnServer(record) {
    return fetch(API_URL + "workrecord", {
      "method": "PUT",
      "headers": {
        "Content-Type": "application/json",
        "x-access-token": authHeader()["x-access-token"]        
      },
      "body": JSON.stringify(record)
    })
    .then(response => {
      return response
    })
    .catch(err => {
      console.error(err);
    });
  }
}

export default new TicketService();